<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>用户信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="申请人姓名">
                <div style="width: 500px;">
                  {{ formData.applicantName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="区域">
                <div style="width: 500px;">
                  {{ formData.areaCodeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="申请人地址">
                <div style="width: 500px;">
                  {{ formData.applicantAddress }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="出生日期">
                <div style="width: 500px;">
                  {{ formData.applicantBirthday }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="联系人姓名">
                <div style="width: 500px;">
                  {{ formData.contactName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="联系人电话">
                <div style="width: 500px;">
                  {{ formData.contactPhone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注">
                <div style="width: 500px;">
                  {{ formData.description }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="身体状态" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="特殊情况类型" v-else-if="formData.applicationType === 'SpecialApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="申请项目" v-else-if="formData.applicationType === 'VolunteerApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="性别" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;" v-if="formData.field1 === 0">
                  未知
                </div>
                <div style="width: 500px;" v-else-if="formData.field1 === 1">
                  男
                </div>
                <div style="width: 500px;" v-else>
                  女
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="申请人保险信息" v-else>
                <div style="width: 500px;">
                  {{ formData.field1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="有无子女陪护" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;" v-if="formData.field2 === 0">
                  无
                </div>
                <div style="width: 500px;" v-else>
                  有
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="身份证号" v-else-if="formData.applicationType === 'SpecialApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="每周服务小时数" v-else-if="formData.applicationType === 'VolunteerApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="学历（文化程度）" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="退休金金额" v-else>
                <div style="width: 500px;">
                  {{ formData.field2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="居住状态" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field3 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="养老模式" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field3 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="婚姻状态" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field3 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="失能原因" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field4 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="申请人有无子女" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;" v-if="formData.field4 === 0">
                  无
                </div>
                <div style="width: 500px;" v-else>
                  有
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="工作性质" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field4 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否经过康复治疗" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;" v-if="formData.field5 === 1">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="基础病" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field5 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="收入（范围）" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field5 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否首次申请" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;" v-if="formData.field6 === 1">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="陪护人员" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field6 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="收入来源" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field6 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="子女数量" v-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field7 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="居住状态" v-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field8 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="身体状态" v-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field9 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="需要照顾方式" v-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.field10 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="参保地保险类型" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.longField1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="申请原因" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.longField1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="保险信息" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.longField1 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="保障方式" v-if="formData.applicationType === 'DisabilityApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.longField2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="服务需求" v-else-if="formData.applicationType === 'YangLaoApplicationForm'">
                <div style="width: 500px;">
                  {{ formData.longField2 }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否了解长护服务" v-else-if="formData.applicationType === 'ChangHuApplicationForm'">
                <div style="width: 500px;" v-if="formData.longField2 === 1">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户身份二维码" v-if="formData.codeUrl !== '' && formData.applicationType !== 'VolunteerApplicationForm'">
                <div style="width: 500px;">
                  <span>
                    <img :src="formData.codeUrl" />
                  </span>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="用户身份二维码" v-if="formData.codeUserUrl !== '' && formData.applicationType === 'VolunteerApplicationForm'">
                <div style="width: 500px;">
                  <span>
                    <img :src="formData.codeUserUrl" />
                  </span>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
        <a-divider>执行信息</a-divider>
        <a-spin :spinning="loadingMissonExec">
          <a-list size="large">
            <a-list-item :key="index" v-for="(item, index) in execList">
              <a-list-item-meta :description="item.description">
                <a slot="title">
                  {{ item.userName }}
                </a>
              </a-list-item-meta>
              <div class="list-content">
                <div class="list-content-item">

                </div>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <span style="min-width: 90px;text-align: left;">状态</span>
                  <p style="min-width: 90px;text-align: left;">
                    <a-tag color="#f50" v-if="item.status === 0">待受理</a-tag>
                    <a-tag color="#2db7f5" v-else-if="item.status === 1">审核中</a-tag>
                    <a-tag color="#87d068" v-else-if="item.status === 2">已通过</a-tag>
                    <a-tag color="#108ee9" v-else-if="item.status === 3">退回</a-tag>
                    <a-tag color="#595959" v-else>驳回</a-tag>
                  </p>
                </div>
                <div class="list-content-item">
                  <span style="min-width: 90px;text-align: left;">处理时间</span>
                  <p style="min-width: 90px;text-align: left;">
                    {{ item.createTime }}
                  </p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
      </div>
      <div>
        <p style="cursor: pointer; text-align: center;" v-if="moreData" @click="moreDataFun">点击加载更多...</p>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { formExecList, formInfo } from '@/api/application'
export default {
  name: 'SeeFrom',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      loadingMissonExec: false,
      execList: [],
      moreData: false,
      execParams: {
        formKeyId: 0,
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    formInfoApi (keyId) {
      const _this = this
      _this.execList = []
      _this.execParams.formKeyId = keyId
      _this.execParams.pageNo = 1
      _this.execListApi()
      _this.seeInfoVisible = true
      _this.loading = true
      formInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    },
    execListApi () {
      const _this = this
      _this.loadingMissonExec = true
      formExecList(_this.execParams).then((res) => {
        if (res.errorCode === 0) {
          _this.execList = _this.execList.concat(res.result.data)
          if (res.result.pageNo < res.result.totalPage) {
            _this.moreData = true
          } else {
            _this.moreData = false
          }
        } else {
          _this.$message.error('系统错误')
        }
        _this.loadingMissonExec = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loadingMissonExec = false
      })
    },
    moreDataFun () {
      this.execParams.pageNo += 1
      this.execListApi()
    }
  }
}
</script>

<style lang="less" scoped>
.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
