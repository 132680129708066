<template>
  <a-modal
    title="处理申请"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="dealStatusApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="申请人"
      >
        {{ queryParam.applicantName }}
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="状态"
        :validateStatus="checkParams.missionStatusStatus"
        :help="checkParams.missionStatusStatusMsg"
      >
        <a-select
          style="width:170px"
          v-model="queryParam.status"
          placeholder="请选择状态"
          @change="changeStatus"
        >
          <a-select-option :key="-1" :value="-1">请选择</a-select-option>
          <a-select-option v-for="item in statusLists" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea
          style="width: 90%;"
          :rows="3"
          v-model="queryParam.description"
          placeholder="备注"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { verifyForm } from '@/api/application'
export default {
  name: 'DealForm',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        missionStatusStatus: 'success',
        missionStatusStatusMsg: null
      },
      queryParam: {
        formKeyId: 0,
        formId: '',
        status: -1,
        applicantName: '',
        description: ''
      },
      statusLists: []
    }
  },
  created () {
  },
  methods: {
    ininData (data, statusList) {
      console.log('ininData', data)
      this.statusLists = statusList
      this.checkParams.missionStatusStatus = 'success'
      this.checkParams.missionStatusStatusMsg = null
      this.queryParam.status = data.status
      this.queryParam.applicantName = data.applicantName
      this.queryParam.description = ''
      this.queryParam.formKeyId = data.keyId
      this.queryParam.formId = data.id
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    dealStatusApi () {
      const _this = this
      if (_this.queryParam.status <= -1) {
        _this.checkParams.missionStatusStatus = 'error'
        _this.checkParams.missionStatusStatusMsg = '请选择状态'
        return false
      } else {
        _this.checkParams.missionStatusStatus = 'success'
        _this.checkParams.missionStatusStatusMsg = null
      }
      verifyForm(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeStatus (e) {
      console.log(e)
      this.queryParam.status = e
      if (e <= -1) {
        this.checkParams.missionStatusStatus = 'error'
        this.checkParams.missionStatusStatusMsg = '请选择状态'
      } else {
        this.checkParams.missionStatusStatus = 'success'
        this.checkParams.missionStatusStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
